<template>
	<div class="cart">
		<div class="container">
			<h2 class="background-heading font-brown-semi">Košík</h2>
			<template v-if="$api.cart && $api.cart.count">
				<ProductsTable/>
				<div class="form">
					<!-- Applied shipping discount -->
          <div class="info-box" v-if="$api.cart.shipping && shippingDiscountApplied.length !== 0">
            Máte
            <template v-if="shippingDiscountApplied.methods !== null">vybranou</template>
            dopravu
            <template v-if="shippingDiscountApplied.price === 0">
              zdarma!
            </template>
            <template v-else>
              za {{ $price(shippingDiscountApplied.price) }}!
            </template>
          </div>

          <div v-if="$api.cart.shipping && shippingDiscountApplied.length !== 0 && shippingDiscountNext.length !== 0">&nbsp;</div>

          <!-- Next shipping discount -->
          <div class="info-box" v-if="$api.cart.shipping && shippingDiscountNext.length !== 0">
            Přidejte do košíku zboží za dalších
            <strong>
              <template v-if="$api.cart.realTotal">
                {{ $price(shippingDiscountNext.remaining) }}
              </template>
              <template v-else>
                . . .
              </template>
            </strong>
            a budete mít
            <template v-if="shippingDiscountNext.methods !== null">vybranou</template>
            dopravu
            <template v-if="shippingDiscountNext.price === 0">
              ZDARMA!
            </template>
            <template v-else>
              za {{ $price(shippingDiscountNext.price) }}!
            </template>
          </div>

          <div class="row cart-bottom">
            <div class="col-md-7 col-lg-5 col-xl-4">
              <template v-if="!subscriptionSelected">
                <h4><label for="voucher-input">Máte voucher nebo slevový kód?</label></h4>
                <form @submit.prevent="applyDiscount" class="input-group">
                  <template v-if="$api.cart.discount.code">
                    <input type="text" id="voucher-input" :value="$api.cart.discount.code" disabled>
                    <Button color="black" type="button" @click.native="removeDiscount" :loading="discountLoading">
                      Odebrat
                    </Button>
                  </template>
                  <template v-else>
                    <input type="text" id="voucher-input" v-model="discountCode">
                    <Button color="black" type="submit" :loading="discountLoading">
                      Použít
                    </Button>
                  </template>
                </form>
                <p v-if="$api.cart.forbidVoucher && !$api.cart.discount.code" class="forbid-voucher-message">
                  Na produkt {{ $api.cart.forbidVoucher }} lze aplikovat slevový kód pouze ze zakoupeného voucheru.
                </p>
              </template>
            </div>
          </div>

          <!-- Shipping -->
          <template v-if="$api.cart.shipping">
						<h3 class="italic font-brown" ref="shipping-form-heading">Doprava</h3>
						<div class="form-group">
							<label class="custom-check" v-for="(value, id) in $api.cart.shipping" :key="id">
								<span class="label has-float">
									{{ value.name }}
                  <!--
									<template v-if="value.packetaPickup && pickupPoint">
										- {{ pickupPoint.name }}
                    <a href="javascript:void(0)" class="smaller" @click="showPacketaModal">(změnit)</a>
									</template>
									-->
                  <template v-if="value.ppl && value.pickup && pickupPoint">
										- {{ pickupPoint.name }}
                    <a href="javascript:void(0)" class="smaller" @click="showPPLModal">(změnit)</a>
                  </template>
									<span class="right" v-if="!isFreeShipping">{{ $price(getShippingPrice(id)) }}</span>
								</span>
                <span v-if="value.description"
                      :class="`label-description${shipping === id ? ' visible' : ''}`">
                  {{ value.description }}
                </span>
								<input type="radio" name="shipping" v-model="shipping" :value="id">
								<span class="marker"></span>
							</label>
						</div>
						<div class="info-box" v-if="cantUseGibon">
							Pro využití Gibon Delivery přidejte do košíku ještě zboží za
							<strong>
								<template v-if="$api.cart.realTotal">
									{{ $price($api.cart.toGibonShipping) }}
								</template>
								<template v-else>
									. . .
								</template>
							</strong>.
						</div>
					</template>

          <div class="combi-date-picker" v-if="!subscriptionSelected">
            <h4 class="italic font-brown" ref="datepicker-form-heading">
              Termín odeslání
              <HelperTooltip color="white">
                <b>TIP:</b> Budete ginger shoty dávat např. někomu na narozeniny nebo Vánoce za delší dobu a chcete shoty úplně čerstvé? Není problém! Vyberte si den až 5 týdnů dopředu, kdy balení chcete od nás odeslat. Doručení je ihned na druhý den.
              </HelperTooltip>
            </h4>
            <div class="form-group combi-date-picker-input-wrapper">
              <label class="custom-check">
                <span class="label">
                  Odeslat co nejdříve
                </span>
                <input type="checkbox" name="payment" v-model="shipAsap">
                <span class="marker"></span>
              </label>
              <date-picker v-if="!shipAsap" v-model="shipAtDate" format="DD. MM. YYYY" prefix-class="xmx" :disabled-date="isDateDisabled" placeholder="Vyberte datum"></date-picker>
              <span v-else-if="shippingObject && shippingObject.deliveryDelay" class="font-brown-darker line-tighter">
                {{ shippingObject.deliveryDelay }}
              </span>
            </div>
          </div>

					<!-- Payment -->
					<template v-if="requiresPayment">
						<h3 class="italic font-brown" ref="payment-form-heading">Platba</h3>
						<div class="form-group">
							<label class="custom-check" v-for="(value, id) in $api.cart.payment" :key="id">
								<span class="label">
									{{ value.name }}
									<HelperTooltip v-if="value.description" color="white">
										{{ value.description}}
									</HelperTooltip>
									<!--<span class="right">{{ $price(value.price) }}</span>-->
								</span>
								<input type="radio" name="payment" v-model="payment" :value="id">
								<span class="marker"></span>
							</label>
						</div>
					</template>

          <!-- Subscription -->
          <div v-if="$api.cart.subscription.active">
            <h3 class="italic font-brown" ref="subscription-form-heading">Předplatné</h3>
            <p>
              <b v-if="$api.cart.subscription.new">Novinka!</b> Zvolte si způsob a my Vám každý měsíc vyrobíme přesně to, co budete chtít!<br>
              Peníze se odečtou každý měsíc týden před odesláním objednávky. Zrušit můžete kdykoliv.<br>
              Jako odměnu dostanete slevu na každou objednávku a dopravu zdarma již od {{ $price($api.cart.subscriptionFreeShippingMin) }}.
            </p>
            <p v-if="$api.cart.forbidSubscription" class="forbid-voucher-message">
              Produkt {{ $api.cart.forbidSubscription }} nelze zakoupit v rámci předplatného.
            </p>
            <template v-else>
              <div class="form-group">
                <label class="custom-check" v-for="option in subscriptionOptions" :key="option.duration">
                  <span class="label">
                    {{ option.label }}
                    <span v-if="option.discount" class="smaller">
                      (sleva <b>{{ option.discount }} %</b>)
                    </span>
                  </span>
                  <input type="radio" name="subscriptionDuration" v-model="subscriptionDuration" :value="option.duration">
                  <span class="marker"></span>
                </label>
              </div>
              <template v-if="subscriptionSelected">
                <h4 class="italic font-brown" ref="subscription-week-form-heading">Vyber termín odesílání objednávek</h4>
                <div class="form-group">
                  <label class="custom-check" v-for="(value, id) in $api.cart.subscription.week" :key="id">
                    <span class="label">
                      {{ value }}
                    </span>
                    <input type="radio" name="subscriptionWeek" v-model="subscriptionWeek" :value="id">
                    <span class="marker"></span>
                  </label>
                </div>
                <template v-if="subscriptionWeek !== null">
                  <template v-if="subscriptionCanDeliverNextWeek">
                    <h4 class="italic font-brown" ref="subscription-first-order-form-heading">Vyber termín první objednávky</h4>
                    <div class="form-group">
                      <label class="custom-check">
                        <span class="label">
                          Příští týden
                          <span class="smaller">
                            (od {{ subscriptionOrderWeekData.nextWeekOrder.firstDelivery }})
                          </span>
                        </span>
                        <input type="radio" name="subscriptionAsap" v-model="subscriptionAsap" :value="true">
                        <span class="marker"></span>
                      </label>
                      <label class="custom-check">
                        <span class="label">
                          Příští měsíc
                          <span class="smaller">
                            (v týdnu od {{ subscriptionOrderWeekData.normalOrder.firstDelivery }})
                          </span>
                        </span>
                        <input type="radio" name="subscriptionAsap" v-model="subscriptionAsap" :value="false">
                        <span class="marker"></span>
                      </label>
                    </div>
                  </template>

                  <template v-if="!subscriptionCanDeliverNextWeek || subscriptionAsap !== null">
                    <h4 class="italic font-brown" ref="subscription-recap-form-heading">Shrnutí</h4>
                    <div>
                      <ul>
                        <li>
                          <b class="font-brown">První platba:</b>&nbsp;
                          <template v-if="subscriptionAsapSelected || isOnlinePayment">ihned</template>
                          <template v-else>{{ subscriptionOrderDates.firstPayment }}</template>
                        </li>
                        <li class="mb-3">
                          <b class="font-brown">Odeslání první objednávky:</b>&nbsp;
                          v týdnu od {{ subscriptionOrderDates.firstDelivery }}
                        </li>

                        <template v-if="isOnlinePayment">
                          <li>Po odeslání objednávky vyplníte údaje o Vaší platební kartě a <b>zaplatíte první objednávku</b>. Tu odešleme <b>v týdnu od {{ subscriptionOrderDates.firstDelivery }}</b> - většinou vyrábíme v úterý.</li>
                          <li>Následně {{ subscriptionOrderWeekData.paymentWeekText.toLowerCase() }} Vám automaticky odečteme peníze.</li>
                          <li>Týden před provedením každé platby Vás budeme informovat e-mailem a budete mít dostatek času předplatné zrušit, pokud si to rozmyslíte.</li>
                        </template>
                        <template v-else>
                          <li>{{ subscriptionOrderWeekData.paymentWeekText }} Vám pošleme fakturu a instrukce k zaplacení.</li>
                          <li>
                            První faktura Vám přijde
                            <b v-if="subscriptionAsapSelected">ihned</b>
                            <b v-else>{{ subscriptionOrderDates.firstPayment }}</b>
                            a objednávku odešleme <b>v týdnu od {{ subscriptionOrderDates.firstDelivery }}</b> - většinou vyrábíme v úterý.
                          </li>
                        </template>

                        <li>V případě připsání platby odešleme objednávku následující týden.</li>
                        <li>O blížícím se konci předplatného budeme informovat e-mailem a budete mít možnost jej prodloužit.</li>
                        <li v-if="!isOnlinePayment"><b>Tip:</b> Pokud nechcete na platbu myslet, doporučujeme <a href="javascript:void(0)" @click="payment = 'online'">zvolit automatické platby skrze platební bránu PayU</a>.</li>
                      </ul>

                      <label class="custom-check" v-if="isOnlinePayment">
                        <span class="label">
                          Souhlasím s měsíčním automatickým strháváním částky uvedené níže dokud předplatné nevyprší nebo jej nezruším.
                        </span>
                        <input type="checkbox" name="payment" v-model="subscriptionConsent">
                        <span class="marker"></span>
                      </label>
                    </div>
                  </template>
                </template>
              </template>
            </template>
          </div>
				</div>
				<div class="row cart-bottom">
					<div class="col-md-6 col-lg-5 col-xl-4">

					</div>
					<div class="col-md-6 col-lg-7 col-xl-8 final-price-wrapper">
						<TotalPrice :shipping-price="shippingPrice" :payment-price="paymentPrice" @totalChanged="displayTotalChanged" :detailed="true"/>
					</div>
				</div>
				<Button color="brown" class="continue-button" @click.native="checkout" v-if="!cantUseGibon">
					Pokračovat
				</Button>
			</template>
			<div class="row empty-cart" v-else>
				<div class="col-12">
					<h3>
						V košíku zatím nic nemáte.
					</h3>
					<p>
						Co takhle do něj přidat třeba některý z našich <router-link :to="{ name: 'ProductsShotsOpen' }">zázvorových shotů</router-link>?
					</p>
				</div>
			</div>
		</div>
    <PPLModal @selected="pickupPointSelected" v-if="pplPickupModalVisible" />
	</div>
</template>

<script>
	import {EventBus} from "../../event-bus";
	import ProductsTable from "./Cart/ProductsTable";
	import Button from "../components/Button";
	// import Packeta from "../../Packeta";
	import TotalPrice from "./Cart/TotalPrice";
	import HelperTooltip from "../components/HelperTooltip";
  import google from "../../google";
  import PPLModal from '@/views/components/PPLModal.vue';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/locale/cs';

	export default {
		name: "Cart",
		metaInfo: {
			title: "Košík",
			meta: [{
				name: "description",
				content: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie.",
			}]
		},
		components: {
      PPLModal,
			HelperTooltip,
			TotalPrice,
			ProductsTable,
      Button,
      DatePicker,
		},
		data() {
			return {
				shipping: this.$storage.cart.shipping.type,
				payment: this.$storage.cart.payment.type,
        subscriptionDuration: this.$api.cart.subscriptionDuration,
        subscriptionPayments: this.$api.cart.subscriptionPayments,
        subscriptionWeek: this.$storage.cart.subscriptionWeek,
        subscriptionAsap: this.$storage.cart.subscriptionAsap,
        subscriptionConsent: null,
				pickupPoint: this.$storage.cart.shipping.pickupPoint,
				discountCode: null,
				discountLoading: false,
				requiresPayment: true,
        pplPickupModalVisible: false,
        savedModalScroll: 0,
				discountErrors: {
					NOT_FOUND: "Voucher se zadaným kódem nebyl nalezen",
					FULLY_USED: "Tento voucher už byl vyčerpán",
					EXPIRED: "Platnost voucheru vypršela ",
          FORBIDDEN_PRODUCT: "Slevový kód nelze aplikovat na produkt ",
          MIN_ORDER_VALUE: "Slevový kód nelze aplikovat na objednávku za méně než ",
				},

        // Ship at datepicker
        shipAsap: false,
        shipAtDate: null,
        minDate: null,
        maxDate: null,
			};
		},
		mounted() {
			EventBus.$emit("changeTheme", "white");
      google.checkout(1);
		},
    created() {
      this.minDate = new Date();  // today
      this.maxDate = new Date();  // five weeks in the future
      const weeks = 5;
      this.maxDate.setDate(this.maxDate.getDate() + weeks * 7);
      console.log(this.minDate, this.maxDate);
    },
    watch: {
			shipping(value) {
        google.checkoutOption("shipping", value);
				if (!value || this.pickupPoint)
          return;

        const option = this.$api.cart.shipping[value];

        /*if (option.packetaPickup)
					this.showPacketaModal();*/

        if (option.ppl && option.pickup)
          this.showPPLModal();
			},
      payment(value) {
        google.checkoutOption("payment", value);
      },
      subscriptionDuration(duration) {
        this.$api.changeSubscription(duration)
            .then(() => {
              if (this.subscriptionSelected) {
                this.discountCode = null;
              }
            })
            .catch(() => {
              EventBus.$emit("flash", "Nepodařilo se přepočítat ceny v košíku", "error");
            });
      },
      preDiscountTotal(totalPrice) {
        if (!totalPrice) {
          return;
        }

        const minOrderValue = this.$api.cart?.discount?.min_order_value;

        if (totalPrice < minOrderValue) {
          this.removeDiscount();
          EventBus.$emit("flash", `Odebrali jsme vám slevový kód z košíku, minimální hodnota objednávky je ${this.$price(minOrderValue)}. Pokud chcete voucher použít, přidejte do košíku další zboží.`, "error", 10);
        }
      },
		},
		computed: {
      shippingObject() {
        if (!this.$api.cart.shipping || !this.shipping)
          return null;

        return this.$api.cart.shipping[this.shipping];
      },
			shippingPrice() {
				// Only digital products
				if (!this.$api.cart.shipping || !this.shipping)
					return 0;

        return this.getShippingPrice(this.shipping);
			},

			paymentPrice() {
				// Sum is zero
				if (!this.requiresPayment || !this.payment)
					return 0;


				let payment = this.$api.cart.payment[this.payment];

				if (!payment || !payment.price)
					return 0;
				return payment.price;
			},

      preDiscountTotal() {
        if (this.$api.cart.total === null)
          return null;

        return this.$api.cart.subtotal + this.shippingPrice + this.paymentPrice;
      },

      shipAtFormatted() {
        if (this.subscriptionSelected) {
          return null;
        }

        if (this.shipAsap) {
          return "asap";
        }

        if (!this.shipAtDate) {
          return null;
        }

        // Convert to YYYY-MM-DD
        return new Date(this.shipAtDate.getTime() - (this.shipAtDate.getTimezoneOffset()*60*1000))
            .toISOString()
            .split('T')[0];
      },

      isFreeShipping() {
        const discount = this.shippingDiscountApplied;
        return discount.length !== 0 && discount.price === 0 && discount.methods === null;
      },

      shippingDiscountApplied() {
        return this.$api.cart.shippingDiscounts.applied;
      },

      shippingDiscountNext() {
        return this.$api.cart.shippingDiscounts.next;
      },

			cantUseGibon() {
				return this.shipping === "gibon-delivery" && this.$api.cart.toGibonShipping > 0;
			},

      subscriptionSelected() {
        return this.subscriptionDuration !== null && parseInt(this.subscriptionDuration) !== 0;
      },

      isOnlinePayment() {
        return this.payment === "online";
      },

      subscriptionOptions() {
        return Object.entries(this.$api.cart.subscription.options)
            .map(([ duration, option ]) => ({
              duration,
              ...option,
            }))
            // Sort ascending by duration, but keep no subscription at the end
            .sort((x, y) => {
              if (x.duration === '0') {
                return Infinity;
              }

              if (y.duration === '0') {
                return -Infinity;
              }

              return x.duration - y.duration;
            });
      },

      subscriptionOrderDates() {
        return this.subscriptionOrderWeekData[this.subscriptionAsapSelected ? "nextWeekOrder" : "normalOrder"];
      },

      subscriptionOrderWeekData() {
        return this.subscriptionPayments[this.subscriptionWeek];
      },

      subscriptionAsapSelected() {
        return this.subscriptionAsap && this.subscriptionCanDeliverNextWeek;
      },

      subscriptionCanDeliverNextWeek() {
        return this.subscriptionOrderWeekData.canDeliverNextWeek;
      },
		},
		methods: {
			displayTotalChanged(value) {
				if (value === null)
					return;

				this.requiresPayment = value > 0 || this.subscriptionSelected;
			},

			checkout() {
				if (this.$api.cart.shipping && !this.shipping)
					return this.validationError("shipping", "Vyberte prosím způsob dopravy");

        if (this.$api.cart.shipping && !this.subscriptionSelected && !(this.shipAsap || this.shipAtDate !== null))
          return this.validationError("datepicker", "Vyberte prosím termín odeslání");

				if (this.requiresPayment && !this.payment)
					return this.validationError("payment", "Vyberte prosím platební metodu");

        if (this.$api.cart.forbidSubscription) {
          this.subscriptionDuration = 0;
        }

        if (this.subscriptionDuration === null) {
          if (this.$api.cart.subscription.active)
            return this.validationError("subscription", "Vyberte prosím typ předplatného");

          this.subscriptionDuration = 0;
        }

        if (this.subscriptionSelected && this.subscriptionWeek === null)
          return this.validationError("subscription-week", "Vyberte prosím termín odesílání objednávek");

        if (this.subscriptionSelected && this.subscriptionCanDeliverNextWeek && this.subscriptionAsap === null)
          return this.validationError("subscription-first-order", "Vyberte prosím termín první objednávky");

        if (this.subscriptionSelected && this.isOnlinePayment && !this.subscriptionConsent)
          return this.validationError("subscription-recap", "Musíte souhlasit se automatickým strháváním platby");

				// Valid zip not set -> open delivery modal
				if (!this.validateGibonZip()) {
					EventBus.$emit("toggleDeliveryModal", true);

					this.$nextTick(() => {
						EventBus.$once("deliveryModalChanged", open => {
							// Valid zip entered -> continue checkout
							if (!open && this.validateGibonZip())
								this.checkout();
						});
					});
					return false;
				}

				// Set shipping & payment details globally
				this.$set(this.$storage.cart.shipping, "type", this.shipping);
				this.$set(this.$storage.cart.shipping, "price", this.shippingPrice);
				this.$set(this.$storage.cart.shipping, "required", !!this.$api.cart.shipping);
				this.$set(this.$storage.cart.shipping, "pickupPoint", this.pickupPoint);
        this.$set(this.$storage.cart.shipping, "shipAt", this.shipAtFormatted);

				this.$set(this.$storage.cart.payment, "type", this.payment);
				this.$set(this.$storage.cart.payment, "required", this.requiresPayment);
				this.$set(this.$storage.cart.payment, "price", this.paymentPrice);

        this.$set(this.$storage.cart, "subscriptionWeek", this.subscriptionWeek);
        this.$set(this.$storage.cart, "subscriptionAsap", this.subscriptionAsapSelected);

				this.$router.push({
					name: "Checkout"
				});
			},

			validationError(section, message) {
				EventBus.$emit("flash", message, "error");

				let top = this.$refs[section + "-form-heading"].offsetTop;
				top += window.innerWidth > 991.98 ? 90 : -70;

				return window.scrollTo({
					top: top,
					left: 0,
					behavior: "smooth"
				});
			},

      isDateDisabled(d) {
        const weekDay = d.getDay();
        const day = d.getDate();
        const month = d.getMonth()+1;
        const year = d.getFullYear();

        return d <= this.minDate || // in the past
            d >= this.maxDate ||    // long in the future
            weekDay === 0 ||     // Sunday
            weekDay >= 5 ||      // Friday or Saturday
            this.$api.closureDays.some(([day2, month2, year2]) => day === day2 && month === month2 && (year2 === undefined || year2 === year))  // forbidden by backend configuration
            ;
      },

			/*
			showPacketaModal() {
				Packeta.Widget.pick(process.env.PACKETA_API_KEY, this.pickupPointSelected, {
					language: "cs",
					country: "cz",
					webUrl: "https://koju.cz/kosik"
				});
			},
			*/

      showPPLModal() {
        this.savedModalScroll = window.scrollY;
        window.scrollTo(0, 0);  // scroll to the top for the map to display properly
        EventBus.$emit("toggleBodyScroll",false);
        this.pplPickupModalVisible = true;
      },

      pickupPointSelected(selectedPoint) {
        window.scrollTo(0, this.savedModalScroll);
        EventBus.$emit("toggleBodyScroll",true);
        this.pplPickupModalVisible = false;

        if (selectedPoint)
          this.pickupPoint = selectedPoint;

        // No pick-up point selected
        if (!selectedPoint && !this.pickupPoint) {
          this.shipping = null;
          EventBus.$emit("flash", "Vyberte prosím výdejní místo", "error");
        }
      },

      getShippingPrice(id) {
        const shipping = this.$api.cart.shipping[id];

        if (!shipping || !shipping.price)
          return 0;

        const discount = this.shippingDiscountApplied;
        if (discount.length !== 0 && (discount.methods === null || discount.methods.includes(id))) {
          return discount.price;
        }

        return shipping.price;
      },

			applyDiscount() {
				if (!this.discountCode || this.discountLoading)
					return;

				this.discountLoading = true;
				this.$api.applyCartDiscount(this.discountCode, this.shippingPrice)
					.then(() => {
						EventBus.$emit("flash", "Voucher úspěšně uplatněn");
					})
					.catch(response => {
						let errorMessage = "Voucher se nepodařilo uplatnit";
						if (typeof response === "object" && "error" in response && this.discountErrors[response.error])
							errorMessage = `${this.discountErrors[response.error]}${response.suffix ?? ""}`;

						EventBus.$emit("flash", errorMessage, "error");
					})
					.finally(() => {
						this.discountLoading = false;
					});
			},

			removeDiscount() {
				if (this.discountLoading)
					return;

				this.discountLoading = true;
				this.$api.applyCartDiscount(null)
					.then(() => {
						this.discountCode = null;
					})
					.catch(() => {
						EventBus.$emit("flash", "Voucher se nepodařilo odebrat", "error");
					})
					.finally(() => {
						this.discountLoading = false;
					});
			},

			validateGibonZip() {
				if (this.shipping !== "gibon-delivery")
					return true;

				let zip = this.$storage.checkout.shipping["zip"];

				if (!zip || typeof zip !== "string")
					return false;

				return this.$storage.gibonValidZip === zip.replace(/\s/g, '');

			}
		}
	}
</script>
