import axios from "axios";
import Vue from "vue";
import FBPixel from "./FBPixel";
import LocalStorage from "./local-storage";
import google from "./google";

export default {
	_axios: axios.create({
		baseURL: process.env.VUE_APP_API_URL,
		withCredentials: true
	}),

	// Load initial data
	init() {
		return new Promise((resolve, reject) => {
			this._axios.post("content/initial")
				.then(data => {
					Vue.set(this, "_data", data.data);

					Vue.set(Vue.prototype.$api, "texts", this._data.texts);
					Vue.set(Vue.prototype.$api, "textCategories", this._data.textCategories);
					Vue.set(Vue.prototype.$api, "products", this._data.fullProducts);
					Vue.set(Vue.prototype.$api, "combiProducts", this._data.combiProducts);
					Vue.set(Vue.prototype.$api, "productSizes", this._data.productSizes);
					Vue.set(Vue.prototype.$api, "shortProducts", this._data.shortProducts);
					Vue.set(Vue.prototype.$api, "vouchers", this._data.vouchers);
					Vue.set(Vue.prototype.$api, "shippingCountries", this._data.shippingCountries);
					Vue.set(Vue.prototype.$api, "closureDays", this._data.closureDays);
					const defaultShippingCountry = Object.values(this._data.shippingCountries)[0];
					Vue.set(Vue.prototype.$storage.checkout.shipping, "country", defaultShippingCountry);
					Vue.set(Vue.prototype.$storage.checkout.billing, "country", defaultShippingCountry);
					Vue.set(Vue.prototype.$api, "cart", data.data.cart);

					resolve(data.data);
				})
				.catch(error => {
					reject(error.response);
				});
		});
	},

	loadText(id) {
		return new Promise((resolve, reject) => {
			// Text already loaded
			if (typeof this._data.texts[id] !== "undefined")
				return resolve(this._data.texts[id]);

			this._axios.post("content/text/" + id)
				.then(data => {
					Vue.set(this._data.texts, id, data.data);
					Vue.set(Vue.prototype.$api, "texts", this._data.texts);
					resolve(data.data);
				})
				.catch(reject);
		});
	},

	addToCart(product, type, qty, options = {}) {
		return new Promise((resolve, reject) => {
			FBPixel.addToCart(product, type, qty);
			this._axios.post("cart/add", {
				id: product.id,
				type, qty, options
			})
				.then(data => {
					Vue.set(Vue.prototype.$api, "cart", data.data);

					resolve(data.data);
				})
				.catch(error => {
					reject(error.response);
				});
		});
	},

	updateCartQty(id, qty) {
		return new Promise((resolve, reject) => {
			this._axios.post("cart/update", {
				id, qty
			})
				.then(data => {
					Vue.set(Vue.prototype.$api, "cart", data.data);

					resolve(data.data);
				})
				.catch(error => {
					reject(error.response);
				});
		});
	},

	removeFromCart(id) {
		return new Promise((resolve, reject) => {
			this._axios.post("cart/remove", {
				id
			})
				.then(data => {
					Vue.set(Vue.prototype.$api, "cart", data.data);

					resolve(data.data);
				})
				.catch(error => {
					reject(error.response);
				});
		});
	},

	applyCartDiscount(code, shippingPrice = 0) {
		return new Promise((resolve, reject) => {
			this._axios.post("cart/discount", {
				code,
				shippingPrice,
			})
				.then(data => {
					// Custom discount application error
					if (data.data.error)
						reject(data.data);
					else {
						Vue.set(Vue.prototype.$api, "cart", data.data);
						resolve(data.data);
					}
				})
				.catch(error => {
					reject(error.response);
				});
		});
	},

	changeSubscription(duration) {
		return new Promise((resolve, reject) => {
			this._axios.post("cart/change-subscription", {
				duration,
			})
				.then(data => {
					Vue.set(Vue.prototype.$api, "cart", data.data);

					resolve(data.data);
				})
				.catch(error => {
					reject(error.response);
				});
		});
	},

	sendOrder() {
		return new Promise((resolve, reject) => {
			let storage = Vue.prototype.$storage;
			let pickupPoint = storage.cart.shipping.pickupPoint;

			let shippingObject = null;
			if (storage.cart.shipping.required)
				shippingObject = Vue.prototype.$api.cart.shipping[storage.cart.shipping.type];

			storage.checkout.contact.name = storage.checkout.shipping.name;

			this._axios.post("send-order", {
				shipping: storage.cart.shipping.type,
				shipAt: storage.cart.shipping.shipAt,
				payment: storage.cart.payment.type,
				subscriptionWeek: storage.cart.subscriptionWeek,
				subscriptionAsap: storage.cart.subscriptionAsap,
				contactDetails: storage.checkout.contact,
				billingDetails: storage.checkout.billing,
				shippingDetails: shippingObject && !shippingObject.pickup ? storage.checkout.shipping :
					(pickupPoint ? (shippingObject?.ppl ? pickupPoint : pickupPoint.id) : null),
				browserData: this._get3DSBrowserData(),
			})
				.then(data => {
					FBPixel.purchase(data.data.total);
					google.purchase(data.data);
					Vue.set(Vue.prototype.$api, "cart", data.data.cart);
					Vue.set(Vue.prototype.$storage, "order", {
						total: data.data.total,
						paid: data.data.paid,
						code: data.data.code,
						payment_url: data.data.payment_url,
					});
					resolve(data.data);
				})
				.catch(error => {
					if (!error.response || error.response.status !== 400 || !error.response.data || !error.response.data.message)
						reject(null);
					else
						reject(error.response.data.message);
				});

		});
	},

	_get3DSBrowserData() {
		return {
			colorDepth: window.screen.colorDepth,
			screenHeight: window.screen.height,
			screenWidth: window.screen.width,
			javaEnabled: (window.navigator.javaEnabled && window.navigator.javaEnabled()) ?? false,
			language: window.navigator.language,
			timezoneOffset: (new Date()).getTimezoneOffset(),
		};
	},

	checkDelivery(zip, town) {
		const DAYS = {
			"Monday": "pondělí",
			"Tuesday": "úterý",
			"Wednesday": "středu",
			"Thursday": "čtvrtek",
			"Friday": "pátek",
			"Saturday": "sobotu",
			"Sunday": "neděli"
		};

		const DIFFERENT_PREPOSITION = [
			"Wednesday", "Thursday"
		];

		const translateDay = englishName => DAYS[englishName];

		// Return user message for delivery on given days
		const getMessage = days => {
			let message = "Na Vaši adresu doručujeme v";

			// "doručujeme ve středu/čtvrtek"
			if (DIFFERENT_PREPOSITION.includes(days[0]))
				message += "e";

			message += " ";

			if (days.length > 1) {
				message += days.slice(0, days.length - 1).map(translateDay).join(", ");
				message += " a ";
			}

			return message + translateDay(days.slice(-1)) + ".";
		};

		// Zip is valid and deliverable to
		const saveZip = (zip, town) => {
			Vue.set(Vue.prototype.$storage, "gibonValidZip", zip);

			// Save to billing information (if they are not filled yet)
			let shippingZip = Vue.prototype.$storage.checkout.shipping.zip;
			let billingZip = Vue.prototype.$storage.checkout.billing.zip;

			if (!billingZip || billingZip === shippingZip)
				Vue.set(Vue.prototype.$storage.checkout.billing, "zip", zip);

			let shippingTown = Vue.prototype.$storage.checkout.shipping.town;
			let billingTown = Vue.prototype.$storage.checkout.billing.town;

			if (!billingTown || billingTown === shippingTown)
				Vue.set(Vue.prototype.$storage.checkout.billing, "town", town);

			// Save to shipping information
			Vue.set(Vue.prototype.$storage.checkout.shipping, "zip", zip);
			Vue.set(Vue.prototype.$storage.checkout.shipping, "town", town);

			// Save zip and town to local storage
			LocalStorage.set("gibonValidFields", {
				zip: zip,
				town: town
			}, {
				days: 14
			});
		};

		return new Promise((resolve, reject) => {
			if (typeof town !== "string" || !town.trim())
				return reject({
					message: "Vyplňte prosím město",
					requestSuccessful: false
				});

			zip = typeof zip === "string" ? zip.replace(/\s/g, '') : false;

			if (!zip || !zip.match(/^[0-9]{5}$/))
				return reject({
					message: "Zkontrolujte prosím zadané PSČ",
					requestSuccessful: false
				});

			this._axios.get("check-gibon-zip/" + zip)
				.then(data => {
					data = data.data;

					if (data.available) {
						saveZip(zip, town);

						let message = getMessage(data.days);
						resolve({
							message
						}, false);
					} else {
						reject({
							message: "Na Vaši adresu bohužel zatím nedoručujeme chlazené produkty. Stále si ale můžete objednat vouchery nebo si shoty přijít osobně vyzvednout.",
							requestSuccessful: true
						});
					}
				})
				.catch(() => {
					reject({
						message: "Při ověřování adresy došlo k chybě, zkuste to prosím znovu.",
						requestSuccessful: false
					})
				});
		});

	},

	loadMoreReviews(product, offset) {
		return new Promise((resolve, reject) => {
			this._axios.post("content/more-reviews/" + product.id + "/" + offset)
				.then(data => {
					// Merge already loaded reviews with new ones
					let merged = Vue.prototype.$api.products[product.slug].reviews.reviews.concat(data.data);

					Vue.set(
						Vue.prototype.$api.products[product.slug].reviews,
						"reviews",
						merged
					);

					resolve(data.data);
				})
				.catch(error => {
					reject(error.response);
				});
		});
	},


	texts: {},
	textCategories: {},
	products: {},
	combiProducts: {},
	shortProducts: {},
	vouchers: {},
	cart: {},
	shippingCountries: [],
	closureDays: [],
	_data: {
		texts: {},
		textCategories: {},
		fullProducts: {},
		combiProducts: {},
		shortProducts: {},
		productSizes: [],
		vouchers: {},
		popup: {},
		alertBar: {},
		shippingCountries: [],
		closureDays: [],
	}
};